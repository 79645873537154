<template>
  <cs-dialog-form
    title="设置密码"
    :model="formModel"
    :rules="formRules"
    :action="submit"
  >
    <a-form-model-item label="设置密码" prop="passwordNew">
      <a-input-password
        v-model.trim="formModel.passwordNew"
        placeholder="6～20位字母或数字，不能连号"
        :maxLength="20"
      />
    </a-form-model-item>
    <a-form-model-item label="确认密码" prop="password">
      <a-input-password
        v-model.trim="formModel.password"
        placeholder="请再次输入密码"
        :maxLength="20"
      />
    </a-form-model-item>
  </cs-dialog-form>
</template>
<script>
import { Base64 } from "js-base64";
export default {
  props: {
    data: {},
  },
  data() {
    return {
      formModel: {
        userName: "",
        passwordNew: "",
        password: "",
        ...this.data,
      },
      formRules: {
        password: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value && this.formModel.passwordNew != value) {
                return callback(new Error("两次输入密码不一致"));
              }
              callback();
            },
          },
        ],
        passwordNew: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (this.formModel.userName == value) {
                return callback(new Error("密码不能和手机号相同"));
              }
              const errMsg = this.api.validate.password(value);
              if (errMsg) {
                return callback(new Error(errMsg));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    async submit({ password }) {
      return await this.api.service.uac_oapi_sso_setPassword(
        {},
        { password: Base64.encode(password) }
      );
    },
  },
};
</script>